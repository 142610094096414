module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":true,"showCaptions":false,"quality":85},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-5TY9B457PG"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#7f2b2b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":false,"disable":false,"rootMargin":"0% 50%"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Santa Walt","short_name":"SantaWalt","description":"Santa Walt is your safe, super-fun, personalized, and affordable way to visit with Santa from the comfort of your home!","start_url":"/","background_color":"#e0e0e0","theme_color":"#7f2b2b","display":"minimal-ui","icon":"static/favicon/favicon.png","icons":[{"src":"static/favicon/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"static/favicon/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"static/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/favicon/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"static/favicon/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"static/favicon/favicon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"static/favicon/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicon/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/favicon/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"static/favicon/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1176188c76b7271684ec40b7ffb63eee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
